  export default [
    {
      id: 1,
      title: "Plan Management",
      router: "/plan-management",
      dbTableName: "Plan",
      isEditable: true,
      apiURL: "plan",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "planTitle",
          displayName: "Plan Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "planSubText",
          displayName: "Plan SubText",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
          // options: [
          //   { value: "", label: "Select" },
          //   { value: "option1", label: "Option 1" },
          //   { value: "option2", label: "Option 2" },
          // ],
        },
        {
          id: 3,
          name: "planImage",
          displayName: "Plan Image",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          size: 332 / 202,
        },
        {
          id: 4,
          name: "planDescription",
          displayName: "Plan Description",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
        {
          id: 5,
          name: "planCost",
          displayName: "Plan Cost",
          order: 5,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        // {
        //   id: 6,
        //   name: "availableInRural",
        //   displayName: "Available in Rural Area",
        //   order: 6,
        //   editable: true,
        //   displayOnTable: true,
        //   input: "radio",
        //   isActive: true,
        //   dataType: "String",
        //   options:['Yes','No']
        // },
      ],
    },
    {
      id: 2,
      title: "Book An Appointment",
      router: "/book-appointment",
      dbTableName: "BookAppointment",
      isEditable: false,
      apiURL: "book-appointment",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "appointmentDate",
          displayName: "Appointment Date",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "date",
          dataType: "String",
        },
        {
          id: 2,
          name: "subject",
          displayName: "Subject",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
        },
        {
          id: 3,
          name: "phone",
          displayName: "Your Contact Number",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
        },
      ],
    },
    {
      id: 3,
      title: "Main Banner Text",
      router: "/main-banner-text-management",
      dbTableName: "BannerText",
      isEditable: true,
      apiURL: "banner",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "subHeading",
          displayName: "Sub Heading",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 2,
          name: "heading",
          displayName: "Heading",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          dataType: "String",
          required: true,
        },
        {
          id: 4,
          name: "image",
          displayName: "Banner Image",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          dataType: "String",
          required: false,
          size: 1920 / 1070,
        },
      ],
    },
    {
      id: 4,
      title: "Below Banner Text",
      router: "/below-banner-text-management",
      dbTableName: "BelowBannerText",
      isEditable: true,
      apiURL: "below-banner-text",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subtitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 5,
      title: "Quality Text",
      router: "/quality-text-management",
      dbTableName: "QualityText",
      isEditable: true,
      apiURL: "quality-text",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subtitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
        {
          id: 4,
          name: "image",
          displayName: "Image",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          size: 1,
        },
      ],
    },
    {
      id: 6,
      title: "Past Work",
      router: "/past-work-management",
      dbTableName: "PastWork",
      isEditable: true,
      apiURL: "past-work",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Text",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "image",
          displayName: "Image",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 7,
      title: "Feature Heading",
      router: "/feature-heading-management",
      dbTableName: "FeatureHeading",
      isEditable: true,
      apiURL: "feature-heading",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Text",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subtitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "image",
          displayName: "Image",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          size: 780 / 666,
        },
      ],
    },
    {
      id: 8,
      title: "Features Management",
      router: "/feature-management",
      dbTableName: "Features",
      isEditable: true,
      apiURL: "features",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Text",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "description",
          displayName: "Description",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "image",
          displayName: "Image",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 9,
      title: "Testimonial Heading",
      router: "/testimonial-heading-management",
      dbTableName: "TestimonialHeading",
      isEditable: true,
      apiURL: "testimonial-heading",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subTitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 10,
      title: "Testimonial",
      router: "/testimonial-management",
      dbTableName: "Testimonial",
      isEditable: true,
      apiURL: "testimonial",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "name",
          displayName: "Client Name",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "position",
          displayName: "Client Position",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "feedback",
          displayName: "Clients Feedback",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
        {
          id: 4,
          name: "picture",
          displayName: "Clients Picture",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          size: 1,
        },
      ],
    },
    {
      id: 11,
      title: "Welcome Text Management",
      router: "/welcome-text-management",
      dbTableName: "WelcomeText",
      isEditable: true,
      apiURL: "welcometext",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subtitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 12,
      title: "Reach Out Management",
      router: "/reach-out-management",
      dbTableName: "ReachOut",
      isEditable: true,
      apiURL: "reachout",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "email",
          displayName: "Email",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "telephone",
          displayName: "Telephone",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "telephone2",
          displayName: "Telephone 2",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 4,
          name: "address",
          displayName: "Address",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "textarea",
          isActive: true,
          dataType: "String",
        },
        {
          id: 5,
          name: "locationlink",
          displayName: "Location Link",
          order: 5,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 13,
      title: "About Us Background Image",
      router: "/about-us-background-image",
      dbTableName: "AboutUsBGImage",
      isEditable: true,
      apiURL: "aboutus-bg-image",
      multiRecords: false,
      data: [
        {
          id: 2,
          name: "image",
          displayName: "Background Image",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          required: false,
          size: 1920 / 1438,
        },
      ],
    },
    {
      id: 14,
      title: "Social Media Management",
      router: "/social-media-management",
      dbTableName: "SocialMedia",
      isEditable: true,
      apiURL: "social-media",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "facebook",
          displayName: "Facebook URL",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
          required: false,
        },
        {
          id: 2,
          name: "twitter",
          displayName: "Twitter URL",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
          required: false,
        },
        {
          id: 3,
          name: "pintrest",
          displayName: "Pintrest URL",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
          required: false,
        },
        {
          id: 4,
          name: "youtube",
          displayName: "Youtube URL",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
          required: false,
        },
        {
          id: 5,
          name: "instagram",
          displayName: "Instagram URL",
          order: 5,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
          required: false,
        },
      ],
    },
    {
      id: 15,
      title: "Footer About Us Text",
      router: "/footer-about-us",
      dbTableName: "FooterAboutUsText",
      isEditable: true,
      apiURL: "footer-about-us-text",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "footeraboutustext",
          displayName: "Footer About Us Text",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
          required: false,
        },
      ],
    },
    {
      id: 16,
      title: "Footer Background Image",
      router: "/footer-background-image",
      dbTableName: "FooterBackground",
      isEditable: true,
      apiURL: "footer-background",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "image",
          displayName: "Footer Background Image",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          required: false,
          size: 1920 / 1438,
        },
      ],
    },
    {
      id: 17,
      title: "About Us Main Banner Text",
      router: "/about-us-main-banner-text-management",
      dbTableName: "AboutUsBannerText",
      isEditable: true,
      apiURL: "about-us-banner",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "subHeading",
          displayName: "Sub Heading",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 2,
          name: "heading",
          displayName: "Heading",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          dataType: "String",
          required: true,
        },
        {
          id: 4,
          name: "image",
          displayName: "Banner Image",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          dataType: "String",
          required: false,
          size: 1920 / 1070,
        },
      ],
    },
    {
      id: 18,
      title: "About Us Image And Description",
      router: "/about-us-image-and-desc",
      dbTableName: "AboutUsDesc",
      isEditable: true,
      apiURL: "about-us-text",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subtitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
        {
          id: 4,
          name: "image",
          displayName: "Image",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          size: 1,
        },
      ],
    },
    {
      id: 19,
      title: "Meet Our Team",
      router: "/meet-our-team",
      dbTableName: "Team",
      isEditable: true,
      apiURL: "team",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "name",
          displayName: "Name",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "position",
          displayName: "Position",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "image",
          displayName: "Image",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "image",
          isActive: true,
          dataType: "String",
          size: 372 / 649,
        },
        {
          id: 4,
          name: "facebook",
          displayName: "Facebook Link",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 5,
          name: "twitter",
          displayName: "Twritter Link",
          order: 5,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 6,
          name: "youtube",
          displayName: "Youtube Link",
          order: 6,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 20,
      title: "Services Main Banner Text",
      router: "/services-main-banner-text-management",
      dbTableName: "ServicesBannerText",
      isEditable: true,
      apiURL: "services-banner",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "subHeading",
          displayName: "Sub Heading",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 2,
          name: "heading",
          displayName: "Heading",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          dataType: "String",
          required: true,
        },
        {
          id: 4,
          name: "image",
          displayName: "Banner Image",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          dataType: "String",
          required: false,
          size: 1920 / 1070,
        },
      ],
    }, {
      id: 21,
      title: "Services Below Banner Text",
      router: "/services-below-banner-text-management",
      dbTableName: "ServicesBelowBannerText",
      isEditable: true,
      apiURL: "services-below-banner-text",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subtitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 22,
      title: "Services Detail",
      router: "/services-details",
      dbTableName: "Services",
      isEditable: true,
      apiURL: "services-details",
      multiRecords: true,
      data: [
        {
          id: 1,
          name: "subHeading",
          displayName: "Sub Heading",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 2,
          name: "heading",
          displayName: "Heading",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          dataType: "String",
          required: true,
        },
        {
          id: 4,
          name: "image",
          displayName: "Banner Image",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          dataType: "String",
          required: false,
          size: 559 / 372,
        },
      ],
    },
    {
      id: 23,
      title: "Contact Us Form Title",
      router: "/contact-us-form-title",
      dbTableName: "ContactUsFormTitle",
      isEditable: true,
      apiURL: "contact-us-form-title",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "title",
          displayName: "Title",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 2,
          name: "subtitle",
          displayName: "Sub Title",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          isActive: true,
          dataType: "String",
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          isActive: true,
          dataType: "String",
        },
      ],
    },
    {
      id: 24,
      title: "ContactUs Banner Text",
      router: "/contact-us-banner-text-management",
      dbTableName: "ContactBanner",
      isEditable: true,
      apiURL: "contact-banner",
      multiRecords: false,
      data: [
        {
          id: 1,
          name: "subHeading",
          displayName: "Sub Heading",
          order: 1,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 2,
          name: "heading",
          displayName: "Heading",
          order: 2,
          editable: true,
          displayOnTable: true,
          input: "text",
          dataType: "String",
          required: true,
        },
        {
          id: 3,
          name: "description",
          displayName: "Description",
          order: 3,
          editable: true,
          displayOnTable: true,
          input: "ckeditor",
          dataType: "String",
          required: true,
        },
        {
          id: 4,
          name: "image",
          displayName: "Banner Image",
          order: 4,
          editable: true,
          displayOnTable: true,
          input: "image",
          dataType: "String",
          required: false,
          size: 1920 / 1070,
        },
      ],
    },
    {
      id: 25,
      title: "ContactUs Form Filled Data",
      router: "/contact-us-form-filled-data",
      formTitle:"Contact Us Form",
      dbTableName: "ContactUs",
      isEditable: false,
      apiURL: "contactus",
      multiRecords: true,
      returnUrl:'',
      successMessage:'',
      errorMessage:'',
      data:[
          {
              id: 1,
              name: "firstName",
              displayName: "First Name",
              order: 1,
              editable: true,
              displayOnTable: true,
              input: "text",
              isActive: true,
              dataType: "String",
              required: 'Please enter first name',
            },
            {
              id: 2,
              name: "lastName",
              displayName: "Last Name",
              order: 2,
              editable: true,
              displayOnTable: true,
              input: "text",
              isActive: true,
              dataType: "String",
              required: 'Please enter last name',
            },
            {
              id: 3,
              name: "email",
              displayName: "Email",
              order: 3,
              editable: true,
              displayOnTable: true,
              input: "text",
              isActive: true,
              dataType: "String",
              required: 'Email Address is required',
              pattern:{value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/, message:"Please Enter valid Email address"}
            },
            {
              id: 4,
              name: "phone",
              displayName: "Phone",
              order: 4,
              editable: true,
              displayOnTable: true,
              input: "text",
              isActive: true,
              dataType: "String",
              required: 'Phone Number is required',
              pattern:/^\+?[0-9\s-]+$/
            },
            {
              id: 5,
              name: "message",
              displayName: "Message",
              order: 5,
              editable: true,
              displayOnTable: true,
              input: "textarea",
              isActive: true,
              dataType: "String",
              required: 'Please add some message here',
            },
      ]
  },
  
  ];
  