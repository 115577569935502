import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/slick.min.css";

import "./assets/css/all.min.css";
import "./assets/css/font.min.css";
import "./assets/css/style.min.css";
import "./assets/css/woocommerce.css";
import "./assets/css/woocommerce-layout.css";
import "./assets/css/woocommerce-smallscreen.css";
import "./assets/css/main.min.css";
import "./assets/css/shop.min.css";

import "./assets/css/extracss.css";

import "./assets/css/daterangepicker.min.css";
import "./assets/css/elementor-icons.min.css";
import "./assets/css/frontend-legacy.min.css";
import './assets/css/frontend.min.css';
import './assets/css/swiper.min.css';
import './assets/css/post-5.css';
import './assets/css/post-9.css';
import './assets/css/css(1)';
import './assets/css/fontawesome.min.css';
import './assets/css/solid.min.css';
import './assets/css/flaticon_hotel.min.css';
import './assets/css/post-182.css';
import './assets/css/post-1194.css';
import './assets/css/post-178.css';
import './assets/css/post-279.css';


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
