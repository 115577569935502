import { useState } from "react";
import BookAppointment from "./BookAppointment";
import ModalBt from "./ModalBt";

const Header = ({ setSidemenustate }: any) => {
  const getClassName = (page: any) => {
    const baseClasses =
      "menu-item menu-item-type-post_type menu-item-object-page menu-item-home  page_item page-item-9 current_page_item";
    const currentPage =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    switch (page) {
      case "home":
        return currentPage === "" || currentPage === "home"
          ? `${baseClasses} current-menu-item`
          : baseClasses;
      case "about-us":
        return currentPage === page
          ? `${baseClasses} current-menu-item`
          : baseClasses;
      case "services":
        return currentPage === page
          ? `${baseClasses} current-menu-item`
          : baseClasses;
      case "contact-us":
        return currentPage === page
          ? `${baseClasses} current-menu-item`
          : baseClasses;
      default:
        return "";
    }
  };

  const openAppointmentModal=()=>{
    setshowModal(true)
  }
const [showModal,setshowModal] = useState(false);
  return (
    <header
      id="masthead"
      className="site-header overlap-header"
      data-sticky-status="scroll-up-enable"
    >
      <ModalBt show={showModal} modalClose={()=>setshowModal(false)} showFooter={false} >
        <BookAppointment />
      </ModalBt>
      {/* <link rel="stylesheet" id="elementor-post-182-css" href="./assets/css/post-182.css" type="text/css"
				media="all" /> */}
      <div
        data-elementor-type="wp-post"
        data-elementor-id="182"
        className="elementor elementor-182"
      >
        <div className="elementor-inner">
          <div className="elementor-section-wrap">
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-1827a322 elementor-section-content-middle elementor-hidden-tablet elementor-hidden-mobile elementor-section-full_width elementor-section-height-default elementor-section-height-default"
              data-id="1827a322"
              data-element_type="section"
              data-settings="{'background_background':'classNameic'}"
            >
              <div className="elementor-background-overlay"></div>
              <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3d341673 dark-color"
                    data-id="3d341673"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-1e2a7daa elementor-widget__width-auto elementor-widget elementor-widget-cs_menu_toggle"
                          data-id="1e2a7daa"
                          data-element_type="widget"
                          data-widget_type="cs_menu_toggle.default"
                        >
                          <div className="elementor-widget-container">
                            <button
                              className="menu-toggle elementor-widget-menu-toggle"
                              onClick={() => setSidemenustate("show")}
                            >
                              <span className="screen-reader-text">Menu</span>
                              <span className="menu-toggle-icon"></span>
                            </button>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-79543eae elementor-widget__width-auto elementor-widget elementor-widget-cs_menu"
                          data-id="79543eae"
                          data-element_type="widget"
                          data-widget_type="cs_menu.default"
                        >
                          <div className="elementor-widget-container">
                            <nav className="cs-menu main-navigation not-mobile-menu dropdown-dark">
                              <ul
                                id="menu-79543eae"
                                className="menu primary-menu"
                              >
                                <li
                                  id="menu-item-33"
                                  className={getClassName("home")}
                                >
                                  <a href="/" aria-current="page">
                                    <span>Home</span>
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1082"
                                  className={getClassName("about-us")}
                                  // className="menu-item menu-item-type-post_type menu-item-object-page "
                                >
                                  <a href="/about-us">
                                    <span>About Us</span>
                                  </a>
                                </li>
                                <li
                                  id="menu-item-41"
                                  //   className="menu-item menu-item-type-custom menu-item-object-custom"
                                  className={getClassName("services")}
                                >
                                  <a href="/services">
                                    <span>Services</span>
                                  </a>
                                </li>
                                <li
                                  id="menu-item-31"
                                  className={getClassName("contact-us")}
                                >
                                  <a href="/contact-us">
                                    <span>Contact</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15208836 dark-color"
                    data-id="15208836"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-ecaaa3e elementor-align-center elementor-widget elementor-widget-cs_logo"
                          data-id="ecaaa3e"
                          data-element_type="widget"
                          data-widget_type="cs_logo.default"
                        >
                          <div className="elementor-widget-container">
                            <a href="">
                              <img
                                width="255"
                                height="51"
                                src="/images/logo-3-255x51.png"
                                className="attachment-cozystay_255x9999 size-cozystay_255x9999 wp-image-184"
                                alt=""
                                sizes="(max-width: 255px) 100vw, 255px"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-60b6b98c dark-color"
                    data-id="60b6b98c"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-5732c3b6 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                          data-id="5732c3b6"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <a href="tel:+13473654373">
                                Tel: +1 347 365 4373
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-538ee816 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                          data-id="538ee816"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {/* <div className="elementor-widget-container">
														<div className="elementor-text-editor elementor-clearfix">
															<a href="#">EN</a>
															/ <a href="#">FR</a>
														</div>
													</div>  */}
                        </div>
                        <div
                          className="elementor-element elementor-element-2d1c712b elementor-widget__width-auto elementor-widget elementor-widget-cs_button"
                          data-id="2d1c712b"
                          data-element_type="widget"
                          data-widget_type="cs_button.default"
                        >
                          <div className="elementor-widget-container">
                            <link
                              rel="stylesheet"
                              id="elementor-post-1132-css"
                              href="./assets/css/post-1132.css"
                              type="text/css"
                              media="all"
                            />
                            <div
                              className="cs-button-popup cs-popup hide cs-popup-box cs-button-popup-2d1c712b"
                              data-popup-hash="4cc74e8d702eb862c030739d1dc1317d"
                            >
                              <span className="close-button">Close</span>
                              <div className="container">
                                <div
                                  data-elementor-type="wp-post"
                                  data-elementor-id="1132"
                                  className="elementor elementor-1132"
                                >
                                  <div className="elementor-inner">
                                    <div className="elementor-section-wrap">
                                      <section
                                        className="elementor-section elementor-top-section elementor-element elementor-element-43a7146 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="43a7146"
                                        data-element_type="section"
                                        data-settings="{'background_background':'classNameic'}"
                                      >
                                        <div className="elementor-container elementor-column-gap-default">
                                          <div className="elementor-row">
                                            <div
                                              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8e06084"
                                              data-id="8e06084"
                                              data-element_type="column"
                                              data-settings="{'background_background':'classNameic'}"
                                            >
                                              <div className="elementor-column-wrap elementor-element-populated">
                                                <div className="elementor-widget-wrap">
                                                  <div
                                                    className="elementor-element elementor-element-daf9957 elementor-widget elementor-widget-spacer"
                                                    data-id="daf9957"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                  >
                                                    <div className="elementor-widget-container">
                                                      <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3f50bc0 light-color"
                                              data-id="3f50bc0"
                                              data-element_type="column"
                                              data-settings="{'background_background':'classNameic'}"
                                            >
                                              <div className="elementor-column-wrap elementor-element-populated">
                                                <div className="elementor-widget-wrap">
                                                  <div
                                                    className="elementor-element elementor-element-b798e3f elementor-widget elementor-widget-cs_title"
                                                    data-id="b798e3f"
                                                    data-element_type="widget"
                                                    data-widget_type="cs_title.default"
                                                  >
                                                    <div className="elementor-widget-container">
                                                      <div className="cs-title-wrap text-center">
                                                        <div className="cs-subtitle-wrap">
                                                          {/* <span
																														className="cs-subtitle">Choose
																														Date
																														to
																														Search</span>  */}
                                                        </div>
                                                        <h4 className="cs-title" onClick={openAppointmentModal}>
                                                          Book an Appointment
                                                        </h4>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="elementor-element elementor-element-3b4c453 elementor-widget elementor-widget-cs_reservation"
                                                    data-id="3b4c453"
                                                    data-element_type="widget"
                                                    data-widget_type="cs_reservation.default"
                                                  >
                                                    <div className="elementor-widget-container">
                                                      <div className="cs-reservation-form style-block cs-form-square inline-label">
                                                        <form
                                                          className="cs-form-wrap"
                                                          data-date-format="YYYY-MM-DD"
                                                          action=""
                                                          method="GET"
                                                        >
                                                          <div className="cs-form-field cs-check-in">
                                                            <div className="field-wrap">
                                                              <label className="cs-form-label">
                                                                Appointment Date
                                                              </label>
                                                              <div className="field-input-wrap checkin-date">
                                                                <input
                                                                  type="text"
                                                                  className="date-range-picker"
                                                                />
                                                                <input
                                                                  type="text"
                                                                  className="check-in-date"
                                                                  name="checkin"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="cs-form-field cs-rooms cs-has-dropdown">
                                                            <div className="">
                                                              <label className="cs-form-label">
                                                                Subject
                                                              </label>
                                                              <div className="">
                                                                <input
                                                                  type="text"
                                                                  name="rooms"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="cs-form-field cs-submit">
                                                            <div className="field-wrap">
                                                              <button
                                                                className="button"
                                                                role="button"
                                                                type="submit"
                                                              >
                                                                <span className="btn-text">
                                                                  Book
                                                                </span>
                                                              </button>
                                                            </div>
                                                          </div>
                                                          <input
                                                            type="hidden"
                                                            name="search_rooms"
                                                          />
                                                        </form>
                                                      </div>
                                                      <div className="daterangepicker ltr auto-apply show-calendar opensright">
                                                        <div className="ranges"></div>
                                                        <div className="drp-calendar left">
                                                          <div className="calendar-table"></div>
                                                        </div>
                                                        <div className="drp-calendar right">
                                                          <div className="calendar-table"></div>
                                                        </div>
                                                        <div className="drp-buttons">
                                                          <span className="drp-selected"></span>
                                                          <button
                                                            className="cancelBtn btn btn-sm btn-default"
                                                            type="button"
                                                          >
                                                            Cancel
                                                          </button>
                                                          <button
                                                            className="applyBtn btn btn-sm btn-primary"
                                                            disabled
                                                            type="button"
                                                          >
                                                            Apply
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <a
                              className="elementor-button-link button cs-btn-outline cs-btn-small cs-btn-color-white popup-box-enabled"
                              role="button"
                              data-popup-hash="4cc74e8d702eb862c030739d1dc1317d"
                              onClick={openAppointmentModal}
                            >
                              <span className="cs-btn-text">
                                Book Appointment
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-212f26c4 elementor-section-content-middle elementor-hidden-desktop elementor-section-full_width elementor-section-height-default elementor-section-height-default"
              data-id="212f26c4"
              data-element_type="section"
              data-settings="{'background_background':'classNameic'}"
            >
              <div className="elementor-background-overlay"></div>
              <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-2151b475 dark-color"
                    data-id="2151b475"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-5d4f0c6e elementor-widget__width-auto elementor-widget elementor-widget-cs_menu_toggle"
                          data-id="5d4f0c6e"
                          data-element_type="widget"
                          data-widget_type="cs_menu_toggle.default"
                        >
                          <div className="elementor-widget-container">
                            <button
                              className="menu-toggle elementor-widget-menu-toggle"
                              onClick={() => setSidemenustate("show")}
                            >
                              <span className="screen-reader-text">Menu</span>
                              <span className="menu-toggle-icon"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-598779d2"
                    data-id="598779d2"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-4ee0d62d elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-cs_logo"
                          data-id="4ee0d62d"
                          data-element_type="widget"
                          data-widget_type="cs_logo.default"
                        >
                          <div className="elementor-widget-container">
                            <a href="">
                              <img
                                width="255"
                                height="51"
                                src="/images/logo-3-255x51.png"
                                className="attachment-cozystay_255x9999 size-cozystay_255x9999 wp-image-184"
                                alt=""
                                sizes="(max-width: 255px) 100vw, 255px"
                              />{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-550755d4 dark-color"
                    data-id="550755d4"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-652feb45 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                          data-id="652feb45"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <a style={{cursor:'pointer'}} onClick={openAppointmentModal}>Book Appointment</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
